<template>
  <div class="pa-3">
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-dialog
        v-model="showDetail"
        max-width="1100px"
        persistent
        transition="dialog-top-transition"
        @keydown.esc="close"
      >
        <v-card>
          <v-card-title>
            <span class="headline">{{ formTitle }}</span>
            <v-spacer> </v-spacer>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row dense>
                <v-col cols="12" sm="6" md="12">
                  <v-autocomplete
                    v-model="editedItem.cmd_detail_id"
                    :item-value="'id'"
                    :items="products"
                    :filter="customFilter"
                    label="Produit"
                    :rules="[(v) => !!v || 'Produit obligatoire']"
                    @change="produit_change"
                    dense
                  >
                    <template v-slot:selection="{ item }">
                      <!-- HTML that describe how select should render selected items -->
                      {{
                        (item.ref ? item.ref + "-" : "") +
                        (item.product_code ? item.product_code + "-" : "") +
                        item.product_label
                      }}
                    </template>
                    <template v-slot:item="{ item }">
                      <v-list-item-content>
                        <v-list-item-title
                          v-html="
                            (item.ref ? item.ref + '-' : '') +
                            (item.product_code ? item.product_code + '-' : '') +
                            item.product_label
                          "
                        ></v-list-item-title>
                        <v-list-item-subtitle
                          v-html="
                            'Qte Commandée ' +
                            (item.qte ? item.qte : 0) +
                            ' ' +
                            (item.unit ? item.unit : '')
                          "
                        ></v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="8">
                  <v-autocomplete
                    v-model="editedItem.article_id"
                    :item-value="'id'"
                    :items="articles_list"
                    :filter="customFilter"
                    label="Article"
                    :rules="[(v) => !!v || 'Article obligatoire']"
                    dense
                    @change="article_change"
                    :loading="loading"
                  >
                    <template v-slot:selection="{ item }">
                      <!-- HTML that describe how select should render selected items -->
                      {{
                        item
                          ? (item.article_code ? item.article_code + "-" : "") +
                            (item.article_label
                              ? item.article_label
                              : editedItem.product_label) +
                            (item.ref_ext ? "-" + item.ref_ext : "")
                          : editedItem.label
                      }}
                    </template>
                    <template v-slot:item="{ item }">
                      <!-- HTML that describe how select should render selected items -->
                      {{
                        (item.article_code ? item.article_code + "-" : "") +
                        (item.article_label
                          ? item.article_label
                          : editedItem.product_label) +
                        (item.ref_ext ? "-" + item.ref_ext : "")
                      }}
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12" sm="6" md="2">
                  <v-text-field
                    ref="pu"
                    autocomplete="off"
                    class="inputPrice"
                    type="number"
                    dense
                    v-model.number="editedItem.pu"
                    :readonly="readonly"
                    :label="'Prix '"
                    @keydown.enter="$refs.qte.focus()"
                    hide-spin-buttons
                    @focus="$event.target.select()"
                    @input="calc_total"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="2">
                  <v-text-field
                    autocomplete="off"
                    ref="qte"
                    class="inputPrice"
                    type="number"
                    dense
                    v-model="editedItem.qte"
                    :readonly="readonly"
                    :label="
                      editedItem.unit_name ? editedItem.unit_name : 'Quantité'
                    "
                    @input="calc_total"
                    required
                    :rules="[
                      (v) =>
                        !!v ||
                        (editedItem.unit_name
                          ? editedItem.unit_name
                          : 'Quantité') + ' obligatoire',
                      (v) => !v || v > 0 || 'Valeur incorrecte',
                      (v) =>
                        parseFloat(v) <= parseFloat(qte_max) ||
                        'Maximum autorisé ' + qte_max,
                    ]"
                    @keydown.enter="save"
                    :disabled="save_disable"
                    hide-spin-buttons
                    @focus="$event.target.select()"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="2">
                  <span outlined>{{ total }}</span>
                </v-col>
                <!-- <v-col cols="12" sm="6" md="2">
                  <v-text-field
                    ref="poids"
                    autocomplete="off"
                    class="inputPrice"
                    type="number"
                    dense
                    v-model.number="editedItem.poids"
                    :label="'Poids Total(Kg)'"
                    :rules="[(v) => !v || v > 0 || 'Valeur incorrecte']"
                    hide-spin-buttons
                  @focus="$event.target.select()"
                  ></v-text-field>
                </v-col> -->
                <v-col cols="12" sm="1" md="10">
                  <v-text-field
                    autocomplete="off"
                    ref="comment"
                    dense
                    v-model="editedItem.comment"
                    label="Commentaire"
                    :readonly="readonly"
                    @focus="$event.target.select()"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-container fill-height>
              <v-alert v-model="alert" :type="type_alert" dismissible>{{
                message
              }}</v-alert>
              <v-spacer></v-spacer>
              <v-layout row justify-center align-center v-if="progress">
                <v-progress-circular
                  indeterminate
                  :size="30"
                  :width="7"
                  color="purple"
                ></v-progress-circular>
              </v-layout>
            </v-container>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="save"
              :disabled="save_disable"
              v-if="!readonly"
            >
              Enregistrer
            </v-btn>
            <v-btn color="blue darken-1" text @click.stop="close">
              Fermer
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-form>

    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      top
      :color="snackbar_color"
    >
      {{ snackbar_text }}
    </v-snackbar>
  </div>
</template>

<script>
import CREATE_DOSSIER_DETAIL from "../graphql/Import/CREATE_DOSSIER_DETAIL.gql";
import UPDATE_DOSSIER_DETAIL from "../graphql/Import/UPDATE_DOSSIER_DETAIL.gql";

export default {
  components: {},
  name: "dossierdetailform",
  props: {
    item: Object,
    items: Array,
    cmddetails: Array,
    readonly: Boolean,
    showForm: Boolean,
    cmd: Object,
  },

  data: () => ({
    message: "",
    save_disable: false,
    alert: false,
    type_alert: "error",
    service: 0,
    qte_max: 0,
    duedate: null,
    allproduct: false,
    progress: false,
    valid: true,
    snackbar: false,
    snackbar_timeout: 2000,
    snackbar_text: "",
    snackbar_color: "primary",
    editedItem: {},
    combovalue: "",
    isProductClosed: true,
    template: 0,
    attributs: [],
    unfilter: false,
    gamme_list: [],
    produits: [],
    tier_products: [],
    articles_list: [],
    pageInfo: null,
    label: null,
    produit_id: null,
    cs: 0,
    label_ext: "",
    ref_ext: "",
    loading: false,
    total: 0,
    totalkey: 10000,
  }),

  computed: {
    showDetail() {
      return this.showForm;
    },

    formTitle() {
      if (this.item)
        return this.item.id < 0
          ? "Nouvelle Ligne  "
          : "Ligne N° ".concat(parseInt(this.item.index) + 1);
      else return "";
    },
    products() {
      let list = [];
      if (this.cmddetails)
        list = this.cmddetails.filter(
          (elm) =>
            elm.rest_import > 0 || elm.id == this.editedItem.cmd_detail_id
        );
      return list;
    },
  },
  watch: {},

  created() {},
  async mounted() {
    if (this.item) {
      this.editedItem = Object.assign({}, this.item);
      this.produit_change();
      this.article_change();
      this.calc_total();
    }
  },

  methods: {
    calc_total() {
      if (this.editedItem.qte && this.editedItem.pu) {
        this.total = "Total " + this.editedItem.qte * this.editedItem.pu;
      }
    },
    produit_change() {
      let i = this.products.findIndex(
        (elm) => elm.id == this.editedItem.cmd_detail_id
      );
      if (i >= 0) {
        this.product = this.products[i];
        this.editedItem.produit_id = this.products[i].produit_id;
        this.editedItem.pu = this.products[i].pu;
        this.editedItem.label = this.products[i].label;
        this.editedItem.code = this.products[i].code;
        this.editedItem.unit_name = this.products[i].unit_name;
        let tol = this.cmd.tol_qte ? this.cmd.tol_qte : 0;
        let val_tol = (this.products[i].qte * tol) / 100;
        this.qte_max = this.products[i].qte + val_tol;
        this.articles_list = this.product.articles.filter(
          (elm) =>
            (elm.tier_id == this.cmd.tier_id &&
              !this.items.map((elm) => elm.article_id).includes(elm.id)) ||
            elm.id == this.editedItem.article_id
        );
      }
      if (this.articles_list)
        if (this.articles_list.length == 1 || this.item.id > 0) {
          if (this.item.id < 0)
            this.editedItem.article_id = this.articles_list[0].id;
          this.article_change();
        }
    },
    article_change() {
      let i = this.articles_list.findIndex(
        (elm) => elm.article_id == this.editedItem.article_id
      );
      if (i >= 0) {
        this.editedItem.pu = this.articles_list[i].pu;
      }
    },
    customFilter(item, queryText) {
      let textlist = [];
      ["ref", "code", "label"].forEach((element) => {
        const text1 = item[element] ? item[element].toLowerCase() : "";
        textlist.push(text1);
      });

      const searchText = queryText.toLowerCase();
      const index = textlist.findIndex((element) => {
        if (element.includes(searchText)) {
          return true;
        }
      });
      return index !== -1;
    },

    async requette(query, v) {
      let r;
      this.loading = true;
      await this.$apollo
        .query({
          query: query,
          variables: v,
        })
        .then((data) => {
          r = data.data;
          this.loading = false;
        })
        .catch((error) => {
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
          this.loading = false;
        });
      return r;
    },

    close() {
      this.$emit("close");
    },
    async maj(query, v) {
      this.progress = true;
      let r;
      await this.$apollo
        .mutate({
          mutation: query,
          variables: v,
        })
        .then((data) => {
          r = data.data;
          this.progress = false;
        })
        .catch((error) => {
          this.loading = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },
    async save() {
      let i = this.items.findIndex(
        (elm) => elm.article_id == this.editedItem.article_id
      );
      if (i >= 0 && this.editedItem.id < 0) {
        this.alert = true;
        this.message = "Article existe!! ";
        this.type_alert = "error";
        return;
      }
      if (this.$refs.form.validate()) {
        let v;
        let ok = true;

        if (ok) {
          this.save_disable = true;
          this.editedItem.montant =
            parseFloat(this.editedItem.qte) * parseFloat(this.editedItem.pu);
          if (this.editedItem.id > 0) {
            v = {
              dossierimportdetail: {
                id: this.editedItem.id,
                pu: parseFloat(this.editedItem.pu),
                poids: parseFloat(this.editedItem.poids),
                qte: parseFloat(this.editedItem.qte),
                article_id: this.editedItem.article_id,
                cmd_detail_id: this.editedItem.cmd_detail_id,
                comment: this.editedItem.comment,
                write_uid: this.$store.state.me.id,
              },
            };

            await this.maj(UPDATE_DOSSIER_DETAIL, v);
            this.items.splice(this.editedItem.index, 1, this.editedItem);
            this.$emit("change");
          } else {
            v = {
              dossierimportdetail: {
                pu: parseFloat(this.editedItem.pu),
                qte: parseFloat(this.editedItem.qte),
                poids: parseFloat(this.editedItem.poids),
                article_id: this.editedItem.article_id,
                cmd_detail_id: this.editedItem.cmd_detail_id,
                comment: this.editedItem.comment,
                create_uid: this.$store.state.me.id,
                write_uid: this.$store.state.me.id,
              },
            };
            v.dossierimportdetail.fact_id = this.item.fact_id;
            let r = await this.maj(CREATE_DOSSIER_DETAIL, v);
            if (r) {
              this.editedItem.qte_rcpt = 0;
              this.editedItem.qte_rest = this.editedItem.qte;
              this.items.push(this.editedItem);
              this.$emit("add");
            }
          }
          this.close();
          this.save_disable = false;
        }
      }
    },
  },
};
</script>
